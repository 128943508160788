table.utd-table-data-view {
	width: 100%;
	border-collapse: collapse;

	thead {
		tr {
			th {
				text-align: center;
				padding: 0.75rem;
			}
		}
	}

	td {
		padding: .3rem;
	}
}

table.utd-table-data-view tr:nth-child(even) {
	background-color: #f2f2f2;
}

table.utd-table-data-view tr:hover {
	background-color: #ddd;
}

table.utd-table-data-view th {
	padding-top: 8px;
	padding-bottom: 8px;
	text-align: left;
	background-color: #04AA6D;
	color: white;
}

table.utd-table-data-view thead tr.utilities-row th {
	text-align: left;
	padding: 0px;
	color: #2f2f2f;
	font-size: 0.8em;
	font-weight: normal;
}
